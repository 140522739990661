<template>
  <section v-if="isLoggedIn" class="container py-5">
    <div class='col-md-6 mx-auto'>
      <h2>{{ currentUser.firstName }}{{$t('diary-header')}}</h2>
      <div v-if="lang === 'en'" class='row row-cols-1 row-cols-sm-2 mb-5'>
          <div v-for="(item, index) in enOptions" :key="index" class="card mx-auto my-2" :style="'opacity: ' + item.opacity">
              <router-link :to="item.link">
              <div class="card-body" tabindex="0">
                  <h3 class="card-title">{{ item.title }}</h3>
                  <p class="card-text">{{ item.body }}</p>
              </div>
              </router-link>
          </div>
      </div>
      <div v-if="lang === 'hi'" class='row row-cols-1 row-cols-sm-2 mb-5'>
          <div v-for="(item, index) in hiOptions" :key="index" class="card mx-auto my-2" :style="'opacity: ' + item.opacity">
              <router-link :to="item.link">
              <div class="card-body" tabindex="0">
                  <h3 class="card-title">{{ item.title }}</h3>
                  <p class="card-text">{{ item.body }}</p>
              </div>
              </router-link>
          </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {  },
  name: 'Diary',
  metaInfo: {
    title: 'My Diary',
    titleTemplate: '%s | Chachi',
  },
  data: function() {
    return {
        enOptions: [
            {title: "Bookmarks", body: "", opacity: 1, link: "/bookmarks"},
            {title: "Notes", body: "Premium feature. Coming soon!", opacity: .5, link:'/premium'},
            {title: "Worksheets", body: "Premium feature. Coming soon!", opacity: .5, link: '/premium'},
        ],
        hiOptions: [
            {title: "बुक्मार्क", body: "", opacity: 1, link: "/bookmarks"},
            {title: "नोट", body: "प्रीमीयम सुविधा। जल्द ही उपलब्ध!", opacity: .5, link:'/premium'},
            {title: "वर्कशीट", body: "प्रीमीयम सुविधा। जल्द ही उपलब्ध!", opacity: .5, link: '/premium'},
        ]
    };
  },
  computed: {
    ...mapState({
      lang: state => state.lang,
      isLoggedIn: state => state.isLoggedIn,
      currentUser: state => state.currentUser,
      bookmarkCards: state => state.bookmarkCards
    })
  },
  mounted() {
    if (this.lang === 'en') {
      this.enOptions[0].body = this.currentUser.bookmarks.length + " cards saved"
    } else if (this.lang === 'hi') {
      this.hiOptions[0].body = this.currentUser.bookmarks.length + " कार्ड सेव किए हुए हैं"
    }
    // this.options[1].body = this.currentUser.profession
    // this.options[2].body = this.currentUser.state
  },
  methods: {
    fetchAgain() {
      this.$store.dispatch("setCurrentUser")
    }
  }
};
</script>

<style scoped>
.card {
	border-radius: 8px;
  width: 16rem;
}
/* @media screen and (max-width: 768px) {
  .card {
    width: 18rem;
  }
} */
a {
  text-decoration: none;
  color: inherit;
}
</style>